import React from "react";
import BearGif from "./img/bear.gif";
import Btn from "../btn";
import Menu404 from "./menu404";
import "./index.css?vers=2.1.0";

const Page404 = () => {
  document.title = "Page introuvable | Nom";
  return (
    <>
      <Menu404 />
      <div className="bc-beige">
        <section>
          <div className="center marginBottomXxl">
            <Btn
              name="Retour à la page d'accueil"
              destination="/"
              center={true}
            />
          </div>
          <div className="center">
            <img src={BearGif} alt="" />
          </div>
        </section>
      </div>
    </>
  );
};

export default Page404;
