import React from "react";
import PropTypes from "prop-types";
import MenuClassique from "../../header/desktop/menuClassique";
import MenuClassiqueDeux from "../../header/desktop/menuClassiqueDeux";
import MenuImageGrande from "../../header/desktop/menuImageGrande";
import MenuImagePetite from "../../header/desktop/menuImagePetite";

import MenuClassiqueMobile from "../../header/mobile/menuClassique";
import MenuImageMobile from "../../header/mobile/menuImage";

import "./index.css?vers=2.1.0";

const Menu404 = () => {
  return (
    <>
      <div className="desktop">
        <MenuImagePetite
          title=""
          desc="La page demandée n'a pas pu être trouvée."
          texte=""
        />
      </div>

      <div className="mobile">
        <MenuClassiqueMobile
          title=""
          desc="La page demandée n'a pas pu être trouvée."
          texte=""
        />
      </div>
    </>
  );
};

export default Menu404;
