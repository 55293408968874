import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import "./End.css";

const End = ({ tenta, time, reset, nbCard }) => {
  return (
    <>
      <div className="root">
        <div className="screen">
          <div className="close" onClick={reset}>
            <FontAwesomeIcon icon={faCircleXmark} />
          </div>
          <div className="felications">Félications ! Partie terminée </div>
          <div className="secondes">{time} Secondes</div>
          <div className="tenta">{tenta} Cartes retournées</div>
          <div className="ratio">
            Ratio (Nombre de cartes / Tentatives ) :{" "}
            <span
              style={{
                color:
                  Math.floor((nbCard / tenta) * 100) > 50 ? "green" : "red",
                textDecoration: "underline",
              }}
            >
              {Math.floor((nbCard / tenta) * 100)}%
            </span>
          </div>
          <div style={{ width: "50%" }} className="btn btn2" onClick={reset}>
            Recommencer une partie
          </div>
        </div>
        <div className="end" onClick={reset}></div>
      </div>
    </>
  );
};

export default End;
