import React, { useState } from "react";
import PropTypes from "prop-types";
import DataNavigation from "../../dataNavigation";
import DataContent from "../../dataContent";
import DataSlider from "../../dataSlider";
import Btn from "../../../btn";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "./index.css?vers=2.1.0";
import "../../../slider/index.css?vers=2.1.0";

const MenuSlider = ({ data, logo, btnDestination, btnName, color }) => {
  const [imageVisible, setImageVisible] = useState(0);

  const Prev = () => {
    if (imageVisible === 0) {
      setImageVisible(data.length - 1);
    } else {
      setImageVisible(imageVisible - 1);
    }
  };

  const Next = () => {
    if (imageVisible === data.length - 1) {
      setImageVisible(0);
    } else {
      setImageVisible(imageVisible + 1);
    }
  };

  return (
    <>
      <div className="relative overFlowHidden height100Vh apparitionOpacity">
        <div className={"absoluteMenuSlider"}>
          <div className={color + " width80 auto padding20"}>
            <div className="flex alignItemCenter justifyContentBetween">
              <Link to="/">
                <div className="flex alignItemCenter hover gap10">
                  <div>
                    <img className="logo" src={logo} alt="" />
                  </div>
                  <h1 className="h2">{DataContent.nomEntreprise}</h1>
                </div>
              </Link>
              <div className="relative">
                <div className="backgroundForMenuSlider"></div>
                <ul className="flex h4 alignItemCenter">
                  {DataNavigation.map((item, key) => (
                    <li key={key}>
                      {item.sousMenu ? (
                        <div className="relative flex column">
                          <div className="overLiMenu overDeclencheMenu padding20">
                            {item.name}
                            <div className="hoverSousMenu flex column">
                              {item.sousMenu.map((item, key) => (
                                <Link key={key} to={item.destination}>
                                  <div className="padding20 bcMenuOver">
                                    {item.name}
                                  </div>
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Link to={item.destination}>
                          <div className="hover borderRadius5 padding20">
                            {item.name}
                          </div>
                        </Link>
                      )}
                    </li>
                  ))}
                  &nbsp;&nbsp;
                  {btnName && (
                    <Btn
                      name={btnName}
                      destination={btnDestination}
                      bc="white "
                      color="black "
                    />
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <i
          className="material-icons arrowLeft hover unclickable"
          onClick={() => Prev()}
        >
          keyboard_arrow_left
        </i>
        <i
          className="material-icons arrowRight hover unclickable"
          onClick={() => Next()}
        >
          keyboard_arrow_right
        </i>
        {data.map((item, key) => (
          <div
            className={key === imageVisible ? "apparitionOpacity" : "none"}
            key={key}
          >
            {(item.desc || item.title) && (
              <div className="descSliderMenu black bc-white padding10_20 borderRadius5 center opacity07">
                {item.title && <div className="h1 uppercase">{item.title}</div>}
                {item.desc && (
                  <div
                    className={
                      (item.title && "padding20_0 ") + " h3 noPaddingBottom"
                    }
                  >
                    {item.desc}
                  </div>
                )}
              </div>
            )}
            <img className="sliderImg  height100Vh" src={item.img} alt="" />
          </div>
        ))}
      </div>
    </>
  );
};

MenuSlider.propTypes = {
  logo: PropTypes.any,
  bc: PropTypes.string,
  btnDestination: PropTypes.string,
  btnName: PropTypes.string,
  data: PropTypes.any,
  color: PropTypes.string,
};

MenuSlider.defaultProps = {
  logo: DataContent.logo,
  bc: DataContent.bc,
  btnDestination: DataContent.btnDestination,
  btnName: DataContent.btnName,
  color: DataContent.color,
  data: DataSlider,
};
export default MenuSlider;
