import ModeleHarryPotter from "./img/harryPotter/modele/modele.jpg";
import ModeleNombre from "./img/nombres/modele/modele.jpg";
import ModeleFoot from "./img/foot/modele/modele.jpg";
import ModeleBasket from "./img/basket/modele/modele.jpg";
import ModeleNourriture from "./img/nourriture/modele/modele.jpg";

export default [
  {
    id: 1,
    name: "Harry-Potter",
    src: ModeleHarryPotter,
  },
  {
    id: 2,
    name: "Nombres",
    src: ModeleNombre,
  },
  {
    id: 3,
    name: "Football",
    src: ModeleFoot,
  },

  {
    id: 4,
    name: "Basket-ball",
    src: ModeleBasket,
  },
  {
    id: 5,
    name: "Nourriture",
    src: ModeleNourriture,
  },
];
