import React from "react";
import Menu from "../../components/atoms/header/menu";
import "./index.css?vers=2.1.0";

const Home = () => {
  document.title = "Card - Accueil";
  return <Menu />;
};

export default Home;
