import React from "react";
import DataNavigation from "../../dataNavigation";
import DataContent from "../../dataContent";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "./index.css?vers=2.1.0";

const MenuClassiqueDeux = ({ bc, color, desc, logo }) => {
  bc = "bc-" + bc;
  return (
    <>
      <div className={bc}>
        <div
          className={color + " width80 auto padding30 relative noPaddingBottom"}
        >
          <div className="absoluteCenterLeft">
            <Link to="/">
              <img className="logo hover" src={logo} alt="" />
            </Link>
          </div>
          <div className="flex gap10 alignItemCenter marginBottomXxxxxl justifyContentCenter">
            <h1 className="h1">{DataContent.nomEntreprise}</h1>
            <p className="h4 red">{desc}</p>
          </div>
          <ul className="flex justifyContentCenter h3">
            {DataNavigation.map((item, key) => (
              <li key={key}>
                {item.sousMenu ? (
                  <div className="relative gap20 flex column">
                    <div className="overLiMenu overDeclencheMenu padding20">
                      {item.name}
                      <div className="hoverSousMenu flex column">
                        {item.sousMenu.map((item, key) => (
                          <Link to={item.destination}>
                            <div className="padding20 bc-gray hover">
                              {item.name}
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <Link to={item.destination}>
                    <div className="overLiMenu padding20">{item.name}</div>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

MenuClassiqueDeux.propTypes = {
  logo: PropTypes.any,
  bc: PropTypes.string,
  color: PropTypes.string,
  desc: PropTypes.string,
};

MenuClassiqueDeux.defaultProps = {
  logo: DataContent.logo,
  bc: DataContent.bc,
  color: DataContent.color,
  desc: DataContent.desc,
};

export default MenuClassiqueDeux;
