import Messi from "./img/foot/personnages/Messi.jpg";
import Mbappe from "./img/foot/personnages/Mbappe.jpg";
import Ronaldo from "./img/foot/personnages/Ronaldo.jpg";
import Neymar from "./img/foot/personnages/Neymar.jpg";
import Ibra from "./img/foot/personnages/Ibra.jpg";
import Pauleta from "./img/foot/personnages/Pauleta.jpg";

export default [
  { id_u: 1, id: 1, url: Messi, theme: "Football" },
  { id_u: 2, id: 2, url: Mbappe },
  { id_u: 3, id: 3, url: Ronaldo },
  { id_u: 4, id: 4, url: Neymar },
  { id_u: 5, id: 5, url: Ibra },
  { id_u: 6, id: 6, url: Pauleta },
  { id_u: 9, id: 1, url: Messi },
  { id_u: 10, id: 2, url: Mbappe },
  { id_u: 11, id: 3, url: Ronaldo },
  { id_u: 12, id: 4, url: Neymar },
  { id_u: 13, id: 5, url: Ibra },
  { id_u: 14, id: 6, url: Pauleta },
];
