import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "./context/ThemeProvider";
import Remonter from "./components/atoms/remonter";
import Home from "./pages/home";
import { useLocation } from "react-router-dom";
import Page404 from "./components/atoms/page404";
import Footer from "./components/atoms/footer/index";
import Jeu from "./pages/play";

const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return "";
};

const App = () => {
  return (
    <>
      <Router forceRefresh={true}>
        {/* <ScrollToTop /> */}
        <Switch>
          <ThemeProvider>
            <Route exact path="/" component={Home} />
            <Route exact path="/jouer" component={Jeu} />
          </ThemeProvider>
          <Route path="/" component={Page404} />
        </Switch>
        <Footer />
        <Remonter />
      </Router>
    </>
  );
};

export default App;
