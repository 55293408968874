import Lebron_James from "./img/basket/personnages/Lebron_James.jpg";
import Steph_curry from "./img/basket/personnages/Steph_curry.jpg";
import Michael_Jordan from "./img/basket/personnages/Michael_Jordan.jpg";
import Giannis from "./img/basket/personnages/Giannis.png";
import Larry from "./img/basket/personnages/Larry.jpg";
import Harden from "./img/basket/personnages/Harden.jpg";

export default [
  { id_u: 1, id: 1, url: Lebron_James, theme: "Basket-ball" },
  { id_u: 2, id: 2, url: Steph_curry },
  { id_u: 3, id: 3, url: Michael_Jordan },
  { id_u: 4, id: 4, url: Giannis },
  { id_u: 5, id: 5, url: Larry },
  { id_u: 6, id: 6, url: Harden },
  { id_u: 9, id: 1, url: Lebron_James },
  { id_u: 10, id: 2, url: Steph_curry },
  { id_u: 11, id: 3, url: Michael_Jordan },
  { id_u: 12, id: 4, url: Giannis },
  { id_u: 13, id: 5, url: Larry },
  { id_u: 14, id: 6, url: Harden },
];
