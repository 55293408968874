import React, { useState } from "react";
import MenuClassique from "../desktop/menuClassique";
import MenuClassiqueDeux from "../desktop/menuClassiqueDeux";
import MenuImageGrande from "../desktop/menuImageGrande";
import MenuImagePetite from "../desktop/menuImagePetite";
import MenuSlider from "../desktop/menuSlider";

import MenuClassiqueMobile from "../mobile/menuClassique";
import MenuImageMobile from "../mobile/menuImage";
import "./index.css?vers=2.1.0";

const Menu = () => {
  return (
    <>
      <div className="desktop">
        <MenuSlider />
      </div>

      <div className="mobile">
        <MenuImageMobile title="Bienvenue sur Card Memory" />
      </div>
    </>
  );
};

export default Menu;
