import Ron_Weasley from "./img/harryPotter/personnages/RonWeasley.jpg";
import Harry_Potter from "./img/harryPotter/personnages/Harry_Potter.jpg";
import Hermione_Granger from "./img/harryPotter/personnages/Hermione_Granger.jpg";
import Vol_de_mort from "./img/harryPotter/personnages/Vol_de_mort.jpg";
import Dobby from "./img/harryPotter/personnages/Dobby.jpg";
import Ginny_Weasley from "./img/harryPotter/personnages/Ginny_Weasley.jpg";

export default [
  { id_u: 1, id: 1, url: Ron_Weasley, theme: "Harry-Potter" },
  { id_u: 2, id: 2, url: Harry_Potter },
  { id_u: 3, id: 3, url: Hermione_Granger },
  { id_u: 4, id: 4, url: Vol_de_mort },
  { id_u: 5, id: 5, url: Dobby },
  { id_u: 6, id: 6, url: Ginny_Weasley },
  { id_u: 9, id: 1, url: Ron_Weasley },
  { id_u: 10, id: 2, url: Harry_Potter },
  { id_u: 11, id: 3, url: Hermione_Granger },
  { id_u: 12, id: 4, url: Vol_de_mort },
  { id_u: 13, id: 5, url: Dobby },
  { id_u: 14, id: 6, url: Ginny_Weasley },
];
