import React, { useEffect, useState } from "react";
import MenuClassique from "../../components/atoms/header/desktop/menuClassique";
import MenuClassiqueMobile from "../../components/atoms/header/mobile/menuClassique";
import Modeles from "../../mocks/modeles";
import "./index.css?vers=2.1.0";
import Card from "../../components/molecules/card/Card";
import Nombres from "../../mocks/number";
import HarryPotter from "../../mocks/harryPotter";
import Football from "../../mocks/foot";
import Basket from "../../mocks/basket";
import Nourriture from "../../mocks/nourriture";

const Jeu = () => {
  const [data, setData] = useState();

  function HandleData(data) {
    let theme = "";
    switch (data) {
      case "Harry-Potter":
        theme = HarryPotter;
        break;

      case "Nombres":
        theme = Nombres;
        break;

      case "Football":
        theme = Football;
        break;

      case "Basket-ball":
        theme = Basket;
        break;

      case "Nourriture":
        theme = Nourriture;
        break;
    }
    return setData(theme);
  }

  if (!data) {
    document.title = "Card - Choix du modèle";
  } else {
    document.title = "Card - Partie en cours [...]";
  }

  return (
    <>
      <div className="desktop">
        <MenuClassique />
      </div>

      <div className="mobile">
        <MenuClassiqueMobile />
      </div>
      {!data && (
        <>
          <div
            style={{
              margin: "0 0 10 0",
              background: "aliceblue",
              display: "flex",
              justifyContent: "center",
              gap: "180px",
              alignItems: "center",
              padding: "15px",
              width: "100%",
              boxSizing: "border-box",
              minHeight: "77px",
            }}
          >
            <div className="tenta">Choisissez votre modèle de cartes</div>
          </div>
          <div className={data ? "globalCard" : "playGlobal"}>
            {Modeles.map((item) => {
              return (
                <div
                  className="playRoot"
                  key={item.id}
                  onClick={() => HandleData(item.name)}
                >
                  <div className="playTexte">{item.name}</div>
                  <img className="playImg" src={item.src} />
                </div>
              );
            })}
          </div>
        </>
      )}

      {data && <Card data={data} />}
    </>
  );
};

export default Jeu;
