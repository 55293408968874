import Img1 from "../dataImg/img1.jpg";
import Img2 from "../dataImg/img2.jpg";
import Img3 from "../dataImg/img3.jpg";
import Img4 from "../dataImg/img4.jpg";

const DataSlider = [
  {
    img: Img1,
    title: "Harry Potter",
  },
  {
    img: Img2,
    title: "Football",
  },
  {
    img: Img3,
    title: "Basket-ball",
  },
  {
    img: Img4,
    title: "Nourriture",
  },
];

export default DataSlider;
