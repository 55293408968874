import React, { useState, useEffect } from "react";
import { ThemeContext } from "./ThemeContext";

export const ThemeProvider = ({ children }) => {
  const [visible, setVisible] = useState([]);
  const [find, setFind] = useState([]);
  const [nbTenta, setNbTenta] = useState(0);

  return (
    <ThemeContext.Provider
      value={{ find, setFind, visible, setVisible, nbTenta, setNbTenta }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
