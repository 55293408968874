import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DataContent from "../../dataContent";
import DataNavigation from "../../dataNavigation";
import Btn from "../../../btn";
import Menu from "../menu";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "./index.css?vers=2.1.0";
import "../menuMobile.css?vers=2.1.0";

const MenuImageMobile = ({
  color,
  bcImage,
  orientation,
  btnName,
  btnDestination,
  bcColorMenuOuvert,
  title,
  mailMenuOuvert,
  telMenuOuvert,
}) => {
  const [statusMenu, setStatusMenu] = useState("");

  bcColorMenuOuvert = "bc-" + bcColorMenuOuvert;
  return (
    <>
      <div
        className={
          color +
          " flex justifyContentBetween paddingMenu relative styleImageBackgroundFull"
        }
        style={{ background: "url(" + bcImage + ")" }}
      >
        <div className="filtreImage"></div>
        <div className="relative width100">
          <div className="flex justifyContentBetween alignItemCenter">
            <div>
              <img className="imageLogo" src={DataContent.logo} />
            </div>
            <div>
              <h1 className="h3">{DataContent.nomEntreprise}</h1>
            </div>
            <div
              className="flex justifyContentRight alignItemCenter imageLogo"
              onClick={() => setStatusMenu("openMenu" + orientation)}
            >
              <i className="fas fa-bars barMenu " aria-hidden="true"></i>
            </div>
          </div>
          <div
            style={{ lineHeight: "100px" }}
            className="h3 absoluteCenter center"
          >
            {title}
          </div>
          {statusMenu && (
            <>
              <div
                className={
                  "Menu" +
                  orientation +
                  " absoluteMenu padding20 " +
                  bcColorMenuOuvert +
                  " " +
                  statusMenu
                }
              >
                <div
                  className="croixCloseMenu absolute flex alignItemCenter "
                  onClick={() => setStatusMenu("closeMenu" + orientation)}
                >
                  <i className="fa fa-window-close" aria-hidden="true"></i>
                </div>
                <div className="marginBottomXxxxl"></div>
                <div className="flex alignItemCenter marginBottomXxxxxl">
                  <div>
                    <img className="imageLogo" src={DataContent.logoMobile} />
                  </div>
                  <p className="h1">&nbsp;{DataContent.nomEntreprise}</p>
                </div>
                <ul className="flex h3 column marginBottomXxxxxl">
                  {DataNavigation.map((item, key) => (
                    <li key={key}>
                      {item.sousMenu ? (
                        <Menu item={item} color={color} />
                      ) : (
                        <>
                          <Link to={item.destination}>
                            <p>{item.name}</p>
                          </Link>
                          <div className={"separtor bc-" + color}></div>
                        </>
                      )}
                    </li>
                  ))}
                  {btnName && (
                    <Btn
                      name={btnName}
                      destination={btnDestination}
                      bc="white "
                      color="black "
                    />
                  )}
                </ul>
                <div className="h2 white flex column gap20">
                  <div className="flex">
                    <i className="material-icons selfCenter">mail</i>
                    &nbsp;
                    <a href="mailto:contact@digitac.fr">{mailMenuOuvert}</a>
                  </div>
                  <div className="flex">
                    <i className="material-icons selfCenter">phone</i>
                    &nbsp;
                    <a href="tel:+33695632515">{telMenuOuvert}</a>
                  </div>
                </div>
              </div>
            </>
          )}
          {statusMenu === "openMenu" + orientation && (
            <div
              className="divCloseMenu"
              onClick={() => setStatusMenu("closeMenu" + orientation)}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};
MenuImageMobile.propTypes = {
  title: PropTypes.string,
  btnName: PropTypes.string,
  logo: PropTypes.any,
  color: PropTypes.string,
  btnDestination: PropTypes.string,
  bcImage: PropTypes.string,
  bcColorMenuOuvert: PropTypes.string,
  orientation: PropTypes.string,
  mailMenuOuvert: PropTypes.string,
  telMenuOuvert: PropTypes.string,
};

MenuImageMobile.defaultProps = {
  title: DataContent.title,
  logo: DataContent.logo,
  color: DataContent.color,
  btnName: DataContent.btnName,
  btnDestination: DataContent.btnDestination,
  bcImage: DataContent.bcImage,
  bcColorMenuOuvert: DataContent.bcColorMenuOuvert,
  orientation: "Left",
  mailMenuOuvert: DataContent.mailMenuOuvert,
  telMenuOuvert: DataContent.telMenuOuvert,
};

export default MenuImageMobile;
