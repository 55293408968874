const DataNavigation = [
  {
    name: "Accueil",
    destination: "/",
  },
  {
    name: "Jouer",
    destination: "./jouer",
  },
];

export default DataNavigation;
