import React, { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import UseInterval from "../../atoms/useInterval/UseInterval";
import ItemCard from "./ItemCard";
import End from "../end/End";
import "./Card.css";

const Card = ({ data }) => {
  const [theme] = useState(data[0].theme);
  const [newData, setNewData] = useState([]);
  const [end, setEnd] = useState(false);
  const { setVisible, find, setFind, nbTenta, setNbTenta } =
    useContext(ThemeContext);

  function Random(length) {
    return Math.random() * length;
  }

  for (const key in data) {
    if (nbTenta === 0) {
      data[key] = { ...data[key], order: Random(1000) };
    }
  }

  useEffect(() => {
    nbTenta === 0 || (nbTenta < 1 && toggle());
  }, [nbTenta]);

  useEffect(() => {
    setNewData(data);
  }, [data]);

  const [count, setCount] = useState(0);
  const [toggle] = UseInterval(() => {
    if (nbTenta > 0 && !end) {
      setCount((count) => count + 1);
    }
  }, 1000);

  const resetCounter = () => setCount(0);

  function HandleResetGame() {
    return (
      setEnd(false), setFind([]), setVisible([]), setNbTenta(0), resetCounter()
    );
  }

  useEffect(() => {
    if (data.length / 2 === find.length) {
      setEnd(true);
    }
  }, [find]);

  return (
    <>
      {end && (
        <End
          tenta={nbTenta}
          time={count}
          reset={HandleResetGame}
          nbCard={data.length}
        />
      )}
      <div
        style={{
          margin: "0 0 10 0",
          background: "aliceblue",
          display: "flex",
          justifyContent: "center",
          gap: "180px",
          alignItems: "center",
          padding: "15px",
          width: "100%",
          boxSizing: "border-box",
          minHeight: "77px",
        }}
      >
        {nbTenta === 0 ? (
          <>
            <div className="tenta">
              Cliquez sur une des cartes pour commencer la partie
            </div>
            <div
              style={{ width: "auto" }}
              className="btn btn3"
              onClick={() => window.location.reload(false)}
            >
              Changer de thème
            </div>
          </>
        ) : (
          <>
            <div className="tenta">
              {nbTenta} {nbTenta > 1 ? "Cartes retournées" : "Carte retournée"}
            </div>
            <div className="secondes">
              {count} {count > 1 ? "secondes" : "seconde"}
            </div>
            <div
              style={{ width: "auto" }}
              className="btn btn2"
              onClick={HandleResetGame}
            >
              Recommencer la partie
            </div>
          </>
        )}
      </div>
      <div
        className="playTexte"
        style={{
          textAlign: "center",
          fontSize: 35,
          backgroundColor: "royalblue",
          borderRadius: 0,
        }}
      >
        {theme}
      </div>
      <div className="globalCard">
        {newData &&
          newData
            .sort((a, b) => a.order - b.order)
            .map((item) => {
              return <ItemCard key={item.order} data={item} />;
            })}
      </div>
    </>
  );
};

export default Card;
