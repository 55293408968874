import React, { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../../../context/ThemeContext";

const ItemCard = ({ data }) => {
  const [disabled, setDisabled] = useState(false);
  const { visible, setVisible, find, setFind, nbTenta, setNbTenta } =
    useContext(ThemeContext);

  const HandleData = (data) => {
    if (!visible.includes(data)) {
      setNbTenta(nbTenta + 1);
      if (visible.length !== 2) {
        setVisible([...visible, data]);
      } else {
        setVisible([data]);
      }
    }
  };

  useEffect(() => {
    if (visible.length === 2) {
      if (visible[0].id === visible[1].id) {
        if (!find.includes(visible[0].id)) {
          setFind([...find, visible[0].id]);
        }
      } else {
        setDisabled(true);
        window.setTimeout(() => {
          setVisible([]);
          setDisabled(false);
        }, 500);
      }
    }
  }, [visible]);

  return (
    <div
      className="card"
      onClick={() => !disabled && !find.includes(data.id) && HandleData(data)}
    >
      {find.includes(data.id) ? (
        data.url ? (
          <img
            style={{ height: "200px", width: "100%", borderRadius: "8px" }}
            src={data.url}
          />
        ) : (
          <span
            style={{
              fontFamily: "Worstveld Sling Extra",
              fontSize: 100,
            }}
          >
            {data.objet}
          </span>
        )
      ) : (
        visible.map((item, key) => {
          return (
            item.id_u === data.id_u &&
            (data.url ? (
              <img
                style={{ height: "200px", width: "100%", borderRadius: "8px" }}
                src={data.url}
              />
            ) : (
              <span
                style={{
                  fontFamily: "Worstveld Sling Extra",
                  fontSize: 100,
                }}
              >
                {data.objet}
              </span>
            ))
          );
        })
      )}
    </div>
  );
};

export default ItemCard;
