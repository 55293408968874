import Logo from "../dataImg/logo.png";
import Btn from "../../btn";
import ImageBackground from "../dataImg/grandeImageFondEcran.jpg";

const DataContent = {
  nomEntreprise: "Card-Memory",
  title: "",
  desc: "",
  texte: "",
  // texte: <Btn name="Test" destination="/Test" center={true} />,
  btnName: "Github",
  btnDestination: "",
  logo: Logo,
  bcImage: ImageBackground,
  color: "white",
  bc: "gray ", // Background Menu

  //MOBILE
  bcColorMenuOuvert: "brown", // MOBILE background color du menu ouvert
  logoMobile: Logo,
  fixed: false,
  mailMenuOuvert: "adr@test.fr",
  telMenuOuvert: "+33 6 95 95 95 95",
};

export default DataContent;
