import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Btn from "../btn";
import DataNavigation from "../header/dataNavigation";
import Facebook from "./img/facebook.svg";
import Insta from "./img/instagram.svg";
import Twitter from "./img/twitter.svg";
import "./index.css?vers=2.1.0";

const Footer = () => {
  return (
    <div className="footer bc-footer">
      <div className="footer1 white">
        <div className="grid4 grid4Footer">
          <div>
            <div className="h2 marginBottomXxl">Source</div>
            <p className="h4 marginBottomXl">Boostez votre mémoire</p>
            <div className="flex gap20 gap20Footer">
              <img
                className="SvgReseaux hover"
                src={Facebook}
                alt="Facebook Digitac"
                // onClick={() => {
                //   window.open("");
                // }}
              />
              <img
                className="SvgReseaux hover"
                src={Insta}
                alt="Instagram Digitac"
                // onClick={() => {
                //   window.open("");
                // }}
              />
              <img
                className="SvgReseaux hover"
                src={Twitter}
                alt="Twitter Digitac"
                // onClick={() => {
                //   window.open("");
                // }}
              />
            </div>
          </div>
          <div>
            <div className="h2 marginBottomXxl">Contact</div>
            <p className="texte">
              Montpellier &#x2022; 34 000 &#x2022; France &#x2022; Disponible
              dans toute la France.
            </p>
            <br />
            <p className="texte flex alignItemCenter">
              <i className="material-icons iconLocation">phone</i>
              &nbsp; <a href="tel:+33695632515">+33 6 95 63 25 15</a>
            </p>
            <br />

            <p className="texte flex alignItemCenter">
              <i className="material-icons iconLocation">mail</i>&nbsp;
              <a href="mailto:contact@digitac.fr">contact@adrien-meynard.fr</a>
            </p>
          </div>
          <div>
            <div className="h2 marginBottomXxl">Pages</div>
            <ul className="flex column texte gap10 gap20Footer">
              {DataNavigation.map((item, key) => (
                <li key={key}>
                  {item.sousMenu ? (
                    <>
                      <div>
                        <div className="marginBottomXs">{item.name}</div>
                        <div className="inlineFlex column">
                          {item.sousMenu.map((item, key) => (
                            <Link
                              key={key}
                              className="hover flex alignItemCenter"
                              to={item.destination}
                            >
                              <span className="bouleNoire"></span>
                              {item.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <Link className="hover" to={item.destination}>
                      {item.name}
                    </Link>
                  )}
                </li>
              ))}
              <Btn name="Github" destination="" />
            </ul>
          </div>
        </div>
      </div>
      <hr className="width100 hrFooter" />
      <div className="footer2 texte white">
        Copyright © 2022 Adrien | Propulsé par{" "}
        <a
          className="underline link hover"
          href="https://adrien-meynard.fr"
          target="_blank"
        >
          Adrien-meynard.fr
        </a>
      </div>
    </div>
  );
};

export default Footer;
