import Jap from "./img/nourriture/personnages/Jap.jpg";
import MacDo from "./img/nourriture/personnages/MacDo.jpg";
import Kebab from "./img/nourriture/personnages/Kebab.jpg";
import Raclette from "./img/nourriture/personnages/Raclette.jpg";
import Pizza from "./img/nourriture/personnages/Pizza.jpg";
import Couscous from "./img/nourriture/personnages/Couscous.jpg";

export default [
  { id_u: 1, id: 1, url: Jap, theme: "Nourriture" },
  { id_u: 2, id: 2, url: MacDo },
  { id_u: 3, id: 3, url: Kebab },
  { id_u: 4, id: 4, url: Raclette },
  { id_u: 5, id: 5, url: Pizza },
  { id_u: 6, id: 6, url: Couscous },
  { id_u: 9, id: 1, url: Jap },
  { id_u: 10, id: 2, url: MacDo },
  { id_u: 11, id: 3, url: Kebab },
  { id_u: 12, id: 4, url: Raclette },
  { id_u: 13, id: 5, url: Pizza },
  { id_u: 14, id: 6, url: Couscous },
];
