import React from "react";
import DataNavigation from "../../dataNavigation";
import DataContent from "../../dataContent";
import PropTypes from "prop-types";
import Btn from "../../../btn";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "./index.css?vers=2.1.0";

const MenuImageGrande = ({
  color,
  title,
  logo,
  bcImage,
  desc,
  btnName,
  btnDestination,
}) => {
  return (
    <>
      <div
        className="styleImageBackgroundFull relative"
        style={{ background: "url(" + bcImage + ")" }}
      >
        <div className="filtreImage"></div>
        <div className={color + " width80 auto padding30 relative"}>
          <div className="flex alignItemCenter justifyContentBetween">
            <Link to="/">
              <div className="flex alignItemCenter hover gap10">
                <div>
                  <img className="logo" src={logo} alt="" />
                </div>
                <h1 className="h1">{DataContent.nomEntreprise}</h1>
              </div>
            </Link>
            <ul className="flex h4 alignItemCenter">
              {DataNavigation.map((item, key) => (
                <li key={key}>
                  {item.sousMenu ? (
                    <div className="relative flex column">
                      <div className="overLiMenu overDeclencheMenu padding20">
                        {item.name}
                        <div className="hoverSousMenu flex column">
                          {item.sousMenu.map((item, key) => (
                            <Link to={item.destination}>
                              <div className="padding20 bcMenuOver">
                                {item.name}
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Link to={item.destination}>
                      <div className="hover borderRadius5 padding20">
                        {item.name}
                      </div>
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          className={
            color +
            " width80 flex auto column justifyContentCenter height70vh relative"
          }
        >
          <div className="width40">
            <div className="marginBottomXl h2">{desc}</div>
            <div className="marginBottomXxxxxl bigH1 bold">{title}</div>
            <Btn name={btnName} destination={btnDestination} />
          </div>
        </div>
      </div>
    </>
  );
};

MenuImageGrande.propTypes = {
  title: PropTypes.string,
  btnName: PropTypes.string,
  desc: PropTypes.string,
  logo: PropTypes.any,
  color: PropTypes.string,
  bcImage: PropTypes.any,
  btnDestination: PropTypes.string,
};

MenuImageGrande.defaultProps = {
  title: DataContent.title,
  desc: DataContent.desc,
  logo: DataContent.logo,
  color: DataContent.color,
  bcImage: DataContent.bcImage,
  btnName: DataContent.btnName,
  btnDestination: DataContent.btnDestination,
};

export default MenuImageGrande;
