import React, { Fragment } from "react";
import RemonterCliq from "./img/remonter";
import "./index.css?vers=2.1.0";

const Remonter = () => {
  window.onscroll = () => {
    hauteur_scrool();
  };

  function hauteur_scrool() {
    const hauteur_screen = window.scrollY;
    const getRemonter = document.querySelector(".remonter");

    if (hauteur_screen >= 150) {
      getRemonter.style.marginRight = "0px";
    } else {
      getRemonter.style.marginRight = "-30%";
    }
  }

  return (
    <Fragment>
      <div className="remonter hover" onClick={() => window.scrollTo(0, 0)}>
        <RemonterCliq color="green" />
      </div>
    </Fragment>
  );
};

export default Remonter;
